#header {
    overflow: hidden;
    height: 30em;
    position: relative;
    z-index: 2;
}

#header:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 100%;
    background: url("../public/images/header.jpg") no-repeat;
    background-position: 50% 50%;
    background-size: cover;
}

#logo {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: flex-start;
    position: absolute;
    left: 1%;
    top: 1%;
    z-index: 3;
}

h1 {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50% -50%);
    width: 100%;
    text-transform: uppercase;
    background-color: rgb(255, 235, 205);
    border: 1px solid black;
    box-shadow: 0 0 4px black;
    text-align: center;
    color: black;
    padding: 12px;
    z-index: 2;
}

.navbar  {
    position: absolute !important;
    right: 0;
    width: 97%;
    top: 20%;
    font-weight: bold;
    display: flex;
    justify-content: flex-end !important;
    background: rgb(255, 235, 205);
    box-shadow: 0 0 10px black;
    border: 1px solid black;
    padding: .5em 0;
    z-index: 2;
}


.navbar .nav-link {
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 22px;
    transition: all .5s;
}

.navbar .nav-link:hover {
    color: blueviolet;
    padding: 0 20px 0 0 ;
}

.navbar .navbar-toggler{
    animation: none;
    width: 60px;
}


.navbar-expand-lg .navbar-nav .dropdown-menu {
    background-color: rgb(255, 235, 205);
    border: none;
    opacity: 0; /* Inicialmente oculto */
    transform: translateY(-20px); /* Mueve hacia arriba para la animación */
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.navbar-expand-lg .navbar-nav .show > .dropdown-menu {
    opacity: 1; /* Mostrar cuando el menú está abierto */
    transform: translateY(0); /* Vuelve a la posición normal */
}

/* Efecto fade in para los elementos del menú desplegable */
.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item {
    font-weight: bold;
    opacity: 0; /* Inicialmente oculto */
    transform: translateY(-10px); /* Mueve hacia arriba para la animación */
    transition: opacity 0.5s ease, transform 0.5s ease;
}
.navbar-collapse .collapse .show{
    animation: fadeInDown 0.5s ease;
}
.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-item {
    opacity: 1; /* Mostrar cuando el menú está abierto */
    transform: translateY(0); /* Vuelve a la posición normal */
    animation: fadeInDown 0.5s ease; /* Agrega animación */
}

/* Animación fade in de arriba a abajo */
@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Media queries for responsiveness */

@media (min-width: 992px) {
    .navbar .navbar-expand-lg {
        justify-content: flex-end;
    }
    
}

/* Media queries para responsividad */

@media screen and (max-width: 768px) {
    #header::before {
        background-position: 10% 50%;
    }

    h1 {
        font-size: 18px;
    }

    #logo {
        width: 40px;
        height: 40px;
        top: 0.5%;
    }

    .navbar {
        top: 20%;
    }

    .navbar .nav-link {
        font-size: 18px;
        padding-right: 10px;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item{
        font-size: 18px;
    }
}

@media screen and (max-width: 480px) {
    h1 {
        font-size: 16px;
    }

    #logo {
        width: 35px;
        height: 35px;
        top: 1.5%;
    }

    .navbar {
        top: 20%;
    }

    .navbar .nav-link {
        font-size: 16px;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item{
        font-size: 16px;
    }
}

@media screen and (max-width: 450px) {
    .navbar .nav-link {
        font-size: 12px;
    }
}

@media screen and (max-width: 300px) {
    .navbar .nav-link {
        font-size: 10px;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item{
        font-size: 10px;
    }
}