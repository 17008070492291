.BuyButton-Button is-buttonLayout{
    width: 50%;
}
button {
    background-color: antiquewhite;
    margin: 0 auto;
    padding: 5px;
}
button a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 22px;
    transition: all 0.5s;
}

button:hover {
    animation: 0.5s infinite normal boton;
}

button a:hover {
    padding: 0;
}

@keyframes boton {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(0);
    }
}