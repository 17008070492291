
/* En tu archivo de estilos (por ejemplo, styles.css) */

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
}

.slider-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.carousel {
  background-color: burlywood;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  width: 25%;
}

.carousel-inner {
  width: 100%;
  height: 100%;
}

.carousel-item {
  width: 100%;
  height: 100%;
  padding: 0 0 20em 0;
}
span{
  text-align: center;
}
.carousel-item img {
  max-width: 100%;
  height: auto;
  aspect-ratio: 5/3;
  object-fit: contain;
  
}


.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: black;
}
a:not([href]):not([class]), a:not([href]):not([class]):hover{
  color: black;
  text-decoration: none;
}
h3, p {
  color: black;
}

.btn {
  --bs-mine: antiquewhite;
  background-color: var(--bs-mine);
  border: none;
  margin: 10px auto;
  padding: 5px;
}

.btn a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 22px;
  transition: all 0.5s;
}

.btn:hover {
  animation: 0.5s infinite normal boton;
  --bs-mine: antiquewhite;
  background-color: var(--bs-mine);
}

.btn a:hover {
  padding: 0;
}

@keyframes boton {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0);
  }
}

/* Reglas de medios para pantallas más pequeñas (ajustar según sea necesario) */
@media (max-width: 768px) {
  .slider-container{
    flex-direction: column;
  }
  .carousel {
    width: 80%;
  }
  
  .btn a {
    font-size: 18px;
  }
}
@media (max-width: 408px) {
  .slider-container{
    flex-direction: column;
  }
  .carousel {
    width: 80%;
  }
  .carousel-inner{
    width: 100%;
  }
  .btn a {
    font-size: 18px;
  }
}