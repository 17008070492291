.container {
    z-index: 10000;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    text-align: center;
    justify-content: center;
}
.container .card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: burlywood;
    box-shadow: 0 0 4px black;
    color: black;
    border: 1px solid burlywood;
    border-radius: 8px;
    padding: 2px;
    width: 30%;
    min-height: 300px;
    margin: 10px;
}

.container .card {
    align-items: center;
    background-color: burlywood;
    color: black;
    border: 1px solid burlywood;
    border-radius: 8px;
    padding: 2px;
    width: 100%;
    min-height: 300px;
    margin: 10px;
}

.tituloCard {
    background-color: blanchedalmond;
    box-shadow: 0 0 4px black;
    text-align: center;
    color: black;
    padding: 12px;
}

.imagenes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.container div img {
    width: 80%; /* Make images responsive */
    border-radius: 15px;
    max-width: auto;
    height: auto;
    margin: 10px auto;
    aspect-ratio: 1;
    object-fit: cover;
}

.container div img:hover {
    animation: 2s normal img;
}

@keyframes img {
    0% {
        transform: rotateY(0);
    }
    50% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(0);
    }
}

.card > div {
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 40px;
}

h2 a{
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 22px;
    transition: cubic-bezier() 0.5s;
}
h2 a:hover {
    font-size: 20px;
    text-decoration: underline;
    padding: 0;
}
button {
    background-color: antiquewhite;
    margin: 10px auto;
    padding: 5px;
}
button a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 22px;
    transition: all 0.5s;
}

button:hover {
    animation: 0.5s infinite normal boton;
}

button a:hover {
    padding: 0;
}

@keyframes boton {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(0);
    }
}

div figure {
    text-align: center;
}



div p {
    text-align: center;
    font-weight: bold;
}

/* Media queries for responsiveness */

@media screen and (max-width: 768px) {
    .container .card-container {
        width: 45%; /* Adjust card width for smaller screens */
    }

    .container div img {
        width: 80%; /* Make images responsive */
    }
}

@media screen and (max-width: 480px) {
    .container .card-container {
        width: 100%; /* Adjust card width for even smaller screens */
    }
}
